/**
 * EasyHTTP Library
 * Library for making HTTP requests
 *Von Traversy an Zachi angepasst...
 *request/response is always JSON data
 *
 * @version 1.0.0
 * @author  Seb Zachrau
 * @license MIT
 *
 **/

export default class ZachiHttp {
  async get(url) {
    try {
      const response = await fetch(url);
      const resData = await response.json();
      return resData;
    } catch (e) {
      const resData = {
        status: 500,
        msg: e.message,
      };
      return resData;
    }
  }
  async download(url, data = null) {
    try {
      return;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const name = decodeURIComponent(escape(response.headers.get("pdf_name")));
      const blob = await response.blob();
      // downloadjs(blob, name);
      const result = {
        status: 200,
        msg: "erfolg download",
      };
      return result;
    } catch (e) {
      const resData = {
        status: 500,
        msg: e.message,
      };
      return resData;
    }
  }

  // Make an HTTP POST Request
  async post(url, data) {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const resData = await response.json();
      if (resData.status === undefined) {
        throw new Error("no status defined" + JSON.stringify(resData));
      }
      return resData;
    } catch (e) {
      const resData = {
        status: 500,
        msg: e.message,
      };
      return resData;
    }
  }

  // Make an HTTP PUT Request
  async put(url, data) {
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const resData = await response.json();
      if (resData.status === undefined) {
        throw new Error("no status defined" + JSON.stringify(resData));
      }
      return resData;
    } catch (e) {
      const resData = {
        status: 500,
        msg: e.message,
      };
      return resData;
    }
  }

  // Make an HTTP PUT Request
  async delete(url, data = "") {
    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const resData = await response.json();
      if (resData.status === undefined) {
        throw new Error("no status defined" + JSON.stringify(resData));
      }
      return resData;
    } catch (e) {
      const resData = {
        status: 500,
        msg: e.message,
      };
      return resData;
    }
  }

  // Make an HTTP POST Request
  async postForm(url, data) {
    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      });
      const resData = await response.json();
      return resData;
    } catch (e) {
      const resData = {
        status: 500,
        msg: e.message,
      };
      return resData;
    }
  }
}
