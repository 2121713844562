import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import jump from "jump.js";
// .. geht nicht in debug browser ud dev env?
// am ende nicht vergessen AOS.init();

load_animations();

export function load_animations() {
  // fade
  const scroollableFadeUp = document.querySelectorAll(".scroollableFadeUp");
  scroollableFadeUp.forEach((each) => {
    each.setAttribute("data-aos", "fade-up");
    each.setAttribute("data-aos-offset", "80");
    each.setAttribute("data-aos-duration", "1000");
    each.setAttribute("data-aos-anchorPlacement", "top-bottom");
  });

  const scroollableFadeRight = document.querySelectorAll(".scroollableFadeRight");
  scroollableFadeRight.forEach((each) => {
    each.setAttribute("data-aos", "fade-right");
    each.setAttribute("data-aos-offset", "80");
    each.setAttribute("data-aos-duration", "1000");
    each.setAttribute("data-aos-anchorPlacement", "top-bottom");
  });

  const scroollableFadeLeft = document.querySelectorAll(".scroollableFadeLeft");
  scroollableFadeLeft.forEach((each) => {
    each.setAttribute("data-aos", "fade-left");
    each.setAttribute("data-aos-offset", "80");
    each.setAttribute("data-aos-duration", "1000");
    each.setAttribute("data-aos-anchorPlacement", "top-bottom");
  });

  // zoom
  const scroollableZoomUp = document.querySelectorAll(".scroollableZoomIn");
  scroollableZoomUp.forEach((each) => {
    each.setAttribute("data-aos", "zoom-in");
    each.setAttribute("data-aos-offset", "80");
    each.setAttribute("data-aos-duration", "1500");
    each.setAttribute("data-aos-anchorPlacement", "top-bottom");
  });

  const scroollableZoomRight = document.querySelectorAll(".scroollableZoomRight");
  scroollableZoomRight.forEach((each) => {
    each.setAttribute("data-aos", "zoom-in-right");
    each.setAttribute("data-aos-offset", "80");
    each.setAttribute("data-aos-duration", "1500");
    each.setAttribute("data-aos-anchorPlacement", "top-bottom");
  });

  const scroollableZoomLeft = document.querySelectorAll(".scroollableZoomLeft");
  scroollableZoomLeft.forEach((each) => {
    each.setAttribute("data-aos", "zoom-in-left");
    each.setAttribute("data-aos-offset", "80");
    each.setAttribute("data-aos-duration", "1500");
    each.setAttribute("data-aos-anchorPlacement", "top-bottom");
  });

  // custom
  const scroollableCustomImageZoom = document.querySelectorAll(".scroollableCustomImageZoom");
  scroollableCustomImageZoom.forEach((each) => {
    each.setAttribute("data-aos", "zoom-in");
    each.setAttribute("data-aos-duration", "1800");
    each.setAttribute("data-aos-offset", "300");
    each.setAttribute("data-aos-delay", "300");
    each.setAttribute("data-aos-anchorPlacement", "top-bottom");
  });

  window.addEventListener("load", () => {
    AOS.init();
  });

  window.addEventListener("resize", () => {
    AOS.refresh();
  });
}
