import './Navbar.js';
import GET_Message from './_GET_Message.js';

import Links from './_Links.js';
import Accordins from './_Accordions.js';
import './AllGlider.js';

import './Back2Top.js';
import './Kontakt';

new GET_Message();

new Links();

new Accordins();

const footer_year = document.querySelector('#footer_year');
if (footer_year !== null) {
  var date = new Date();
  footer_year.innerText = date.getFullYear();
}
