import '../../js/Gadgets/_glider_extern';

import Standard from '../../js/Libraries/Standard';
import ZachiHttp from '../../js/Libraries/ZachiHttp';
import Handlers from '../../js/Libraries/Handlers';

class AllGlider extends Standard {
  constructor() {
    super('body');
    if (!super.exist()) return;
    this.hl = new Handlers();
    this.init();
  }

  async init() {
    await this.gliderKundenstimmen();
    await this.gliderProjekte();
  }
  async gliderKundenstimmen() {
    const decks = document.querySelectorAll('.gliderKundenstimmen');

    decks.forEach(async (deck) => {
      const glider = deck.querySelector(`.glider`);
      glider.style.display = 'none';
      await this.hl.wait(400);
      glider.style.display = 'block';
      const gliderPrev = deck.querySelector(`.glider-prev`);
      const gliderNext = deck.querySelector(`.glider-next`);
      const gliderDots = deck.querySelector(`.dots`);

      if (!(glider !== null && gliderPrev !== null && gliderNext !== null && gliderDots !== null)) {
      }

      const arrows = {
        prev: gliderPrev,
        next: gliderNext,
      };
      const responsive = [
        {
          // If Screen Size More than 768px
          breakpoint: 320,
          settings: {
            duration: 1,
            arrows: arrows,
          },
        },
        {
          // If Screen Size More than 768px
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            duration: 1,
            arrows: arrows,
          },
        },
        {
          // If Screen Size More than 768px
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            duration: 1,
            arrows: arrows,
          },
        },
        {
          // If Screen Size More than 768px
          breakpoint: 767.99,
          settings: {
            slidesToShow: 1.5,
            slidesToScroll: 1,
            duration: 1,
            arrows: arrows,
          },
        },
        {
          // If Screen Size More than 768px
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            duration: 1,
            arrows: arrows,
          },
        },
        {
          // If Screen Size More than 1024px
          breakpoint: 1300,
          settings: {
            slidesToShow: 2.5,
            slidesToScroll: 1,
            duration: 1,
            arrows: arrows,
          },
        },
      ];

      new Glider(glider, {
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        dots: gliderDots,
        responsive: responsive,
      });
    });
  }
  async gliderProjekte() {
    const decks = document.querySelectorAll('.gliderProjekte');

    decks.forEach(async (deck) => {
      const glider = deck.querySelector(`.glider`);
      glider.style.display = 'none';
      await this.hl.wait(400);
      glider.style.display = 'block';
      const gliderPrev = deck.querySelector(`.glider-prev`);
      const gliderNext = deck.querySelector(`.glider-next`);
      const gliderDots = deck.querySelector(`.dots`);

      if (!(glider !== null && gliderPrev !== null && gliderNext !== null && gliderDots !== null)) {
      }

      const arrows = {
        prev: gliderPrev,
        next: gliderNext,
      };
      const responsive = [
        {
          // If Screen Size More than 768px
          breakpoint: 320,
          settings: {
            duration: 1,
            arrows: arrows,
          },
        },
        {
          // If Screen Size More than 768px
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            duration: 1,
            arrows: arrows,
          },
        },
        {
          // If Screen Size More than 768px
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            duration: 1,
            arrows: arrows,
          },
        },
        {
          // If Screen Size More than 768px
          breakpoint: 900.99,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            duration: 1,
            arrows: arrows,
          },
        },
        {
          // If Screen Size More than 768px
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            duration: 1,
            arrows: arrows,
          },
        },
        {
          // If Screen Size More than 1024px
          breakpoint: 1300,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            duration: 1,
            arrows: arrows,
          },
        },
      ];

      new Glider(glider, {
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        dots: gliderDots,
        responsive: responsive,
      });
    });
  }
}

new AllGlider();
